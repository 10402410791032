import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import style from "./SuccessFullScreenWithDown.module.css";
import mark from "../../../assets/Group.png";
import line from "../../../assets/Line 7.png";

export default function SuccessfulScreenWithDownloadButton({ onClose }) {
  const navigate = useNavigate();
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);

  const handleDoneClick = () => {
    navigate("/paybills");
  };

  const toggleDownloadOptions = () => {
    setShowDownloadOptions(!showDownloadOptions);
  };

  const handleDownloadPDF = () => {
    navigate("/receipt/electricity/pdf");
  };

  const handleDownloadImage = () => {
    navigate("/receipt/electricity/image");
  };

  return (
    <div className={style.overlay}>
      <div className={style.wrapper2}>
        <div className={style.main}>
          <div className={style.cancelIcon} onClick={handleDoneClick}>&times;</div>
          <div className={style.innerDiv} id="receiptDiv">
            <img src={line} alt="line" />
            <div className={style.text}>
              <img src={mark} alt="mark" />
              <p>Transaction Completed</p>
            </div>
          </div>
          <div className={style.DButton}>
            <button onClick={toggleDownloadOptions} className={style.downloadButton}>
              Download
            </button>
          </div>

          {showDownloadOptions && (
            <div className={style.downloadOptions}>
              <button onClick={handleDownloadPDF}>Download as PDF</button>
              <button onClick={handleDownloadImage}>Download as Image</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
