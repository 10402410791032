import Webcam from "react-webcam";
import "./CameraField.css";
import { useCallback, useRef, useState, useEffect } from "react";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

import close_img from "../../../assets/images/close-icon.png"

const CameraField = ({ setOpenCamera, onCapture, jwt }) => {
  const [selfieImagePath, setSelfieImagePath] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  // Initialize Laravel Echo
  useEffect(() => {
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "0753705c1bd5e374c328",
      cluster: "eu",
      encrypted: true,
    });

    // Listen for real-time events
    window.Echo.channel("selfie-channel").listen("SelfieCaptured", (e) => {
      console.log("Selfie captured:", e.imageData);
      setSelfieImagePath(`data:image/png;base64,${e.imageData}`);
    });

    // Access user's webcam
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
      })
      .catch((err) => {
        console.error("Error accessing the webcam:", err);
      });

    return () => {
      if (window.Echo) window.Echo.disconnect();
    };
  }, []);

  const startSelfieCapture = async () => {
    try {
      const response = await fetch(
        "https://leverpay-api.azurewebsites.net/api/v1/brails-kyc/selfie/start",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.message); // Log: 'Selfie capture started.'
    } catch (error) {
      console.error("Error starting selfie capture:", error);
    }
  };

  const stopSelfieCapture = async (imageData) => {
    try {
      const response = await fetch(
        "https://leverpay-api.azurewebsites.net/api/v1/brails-kyc/selfie/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ image_data: imageData }),
        }
      );
      const data = await response.json();
      console.log(data.message); // Log: 'Selfie captured successfully.'
      setSelfieImagePath(data.image_url);
      onCapture(data.image_url);
    } catch (error) {
      console.error("Error capturing selfie:", error);
    }
  };

  const captureImage = () => {
    const context = canvasRef.current.getContext("2d");
    context.drawImage(
      videoRef.current,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    const imageData = canvasRef.current
      .toDataURL("image/png")
      .replace("data:image/png;base64,", "");
    stopSelfieCapture(imageData);
  };

  const handleClose = () => {
    // Stop the webcam
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }

    // Close the component
    setOpenCamera(false);
  };

  return (
    <div className="camera_field_wrapper">
      <div className="camera_close_btn_wrapper">
        <button onClick={handleClose}>
          <img src={close_img} alt="close icon" />
        </button>
      </div>
      <video ref={videoRef} className="video_recorder" autoPlay></video>
      <canvas
        ref={canvasRef}
        width="640"
        height="480"
        style={{ display: "none" }}
      ></canvas>
      <div className="camera_capture_buttons">
        <button onClick={startSelfieCapture} className="start_selfie">Start Selfie</button>
        <button onClick={captureImage} className="capture_selfie">Capture Selfie</button>
      </div>
      {selfieImagePath && (
        <img id="selfie-display" src={selfieImagePath} alt="Captured Selfie" />
      )}
    </div>
  );
};

export default CameraField;
