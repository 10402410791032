import "./SuccessfullModal.css";
import confirm_img from "../../../assets/images/confirm_success.png";
import { Link } from "react-router-dom";

const SuccessfullModal = () => {
  return (
    <div className="successfull_sub_modal">
      <div className="successful_card_wrapper">
        <img src={confirm_img} alt="successful icon" />
        <p className="mod_kyc_sub_text1">Your KYC form has been Submitted Successfully.</p>
        <p className="mod_kyc_sub_text2">
          Your Account Will be <span>activated </span> after a 
          review from our compliance team and you will be notified.
        </p>

        <Link className="kyc_to_dashboard_link" to="/">Dashboard</Link>
      </div>
    </div>
  );
};

export default SuccessfullModal;
