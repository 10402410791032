import "./FieldVerifyModal.css";

import halfLogo from "../../../assets/images/half-logo.png";
import confirm_success from "../../../assets/images/confirm_success.png";

const FieldVerifyModal = ({
  isOpen,
  onOtpChange,
  otp,
  onConfirmOtp,
  onContinue,
  isOtpEntered,
  fieldToVerify,
  fieldMsg,
}) => {
  if (!isOpen) return null;

  const handleConfirmClick = () => {
    if (!isOtpEntered) {
      onConfirmOtp(); // Validate OTP
    } else {
      onContinue(); // Proceed to the next step if OTP is valid
    }
  };

  const getSuccessMessage = () => {
    if (fieldToVerify === "profileImage") {
      return "Image Captured Successfully.";
    } else {
      return `${
        fieldToVerify.charAt(0).toUpperCase() + fieldToVerify.slice(1)
      } Verification Successful`;
    }
  };

  return (
    <div className="kyc_field_verify_comp_wrapper">
      <div className="verify_otp_confirm_wrapper">
        {!isOtpEntered ? (
          <div className="kyc_verify_otp_container">
            <img src={halfLogo} alt="leverpay half logo" />
            <p className="verification">Verification</p>
            <p className="code_msg">
              Enter the 6 digit code we sent to {fieldMsg}
            </p>

            <div className="otp_field_area">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  value={data}
                  onChange={(e) => onOtpChange(e, index)}
                  maxLength={1}
                />
              ))}
            </div>

            <p className="receive_code_msg">
              Didn’t receive code? <button>Resend</button>
            </p>
            <button className="confirm_btn" onClick={handleConfirmClick}>
              Confirm
            </button>
          </div>
        ) : (
          <div className="verification_status_msg_container">
            <img src={confirm_success} alt="confirm success" />
            <p>{getSuccessMessage()}</p>
            <button onClick={handleConfirmClick}>Continue</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FieldVerifyModal;
